<template>
  <div class="me">
    <div class="meInfo">
      <img src="/static/background_1.png" class="backImg" />
      <div class="content">
        <div class="top">
          <div class="mine">
            <div class="img">
              <img v-if="headimgurl" :src="headimgurl" />
              <img v-else src="/static/user.png" />
            </div>
            <div class="username_box">
              <div class="name">{{ username }}</div>
              <div class="certification" :class="is_realname == 1 ? 'is_realname_bg' : 'not_realname_bg'" @click="goOcr">
                <img :src="authImg" />
                <span>{{ auth }}</span>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="set" @click="goSet">
              <img src="/static/set_icon.svg" />
              <span>设置</span>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="loading">
            <div class="number">{{ record_count }}</div>
            <div class="title">待审核病例数</div>
          </div>
          <div class="loading">
            <div class="number">{{ record_pass_count }}</div>
            <div class="title">审核通过病例数</div>
          </div>
          <div class="loading">
            <div class="number">{{ record_reject_count }}</div>
            <div class="title">审核驳回病例数</div>
          </div>
        </div>
      </div>
    </div>
    <van-cell is-link clickable v-if="showWallet" class="wallet_box" @click="goWallet">
      <template #title>
        <div class="money" style="text-align: left">
          <img src="/static/money.png" />
          <span>公益值中心</span>
        </div>
      </template>
      <template #right-icon>
        <div class="icon_box">
          <van-icon name="arrow" color="#4B99FF" />
        </div>
      </template>
    </van-cell>
    <div class="noIdsn" v-if="!idsn">
      <img src="/static/certification.png" />
      <van-button round type="info" plain hairline size="large" @click="goOcr">立即实名认证，开启个人云医品牌</van-button>
    </div>
    <div class="noProject" v-if="idsn && !(list.length > 0)">
      <div class="myProject">我参与的项目</div>
      <img src="/static/no_project.png" />
      <div class="remark">您还未参与任何项目</div>
      <van-button round type="info" plain hairline @click="goProject">立即参与</van-button>
    </div>
    <div class="projectList" v-if="idsn && list.length > 0">
      <div class="myProject">
        <div class="line_icon"></div>
        我参与的项目
      </div>
      <div class="projectOne" v-for="(item, index) in list" :key="index" @click="goSub(item)">
        <div v-if="!ifClosed" class="project_item_box">
          <!-- <img src="/static/loadingProject.png" /> -->
          <div class="proj_msg_box">
            <div class="projectName">{{ item.name }}</div>
            <div class="status_box">
              <div class="status_icon" v-if="audit_status">
                <img :src="authImgBottom" alt="" />
              </div>
              <div class="status_text" :class="audit_status == '资质认证成功' ? 'pass' : 'unpass'">
                {{ audit_status }}
              </div>
            </div>
          </div>
          <div class="kv_box">
            <img :src="kv_url" />
          </div>
        </div>
      </div>
    </div>
    <Tabbar :name="'me'" :ifCanChange="ifCanChange" @clickTabbar="clickTabbar"></Tabbar>
    <van-dialog v-model="showChangePassword" title="设置密码" :show-confirm-button="false">
      <van-form class="from" style="margin: 16px 10px" input-align="left">
        <van-field v-model="newPassword" required name="newPassword" label="新密码" placeholder="请输入8-18位新密码" type="password" />
        <van-field v-model="rePassword" required name="rePassword" label="再次输入" placeholder="请输入8-18位新密码" type="password" />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit" color="#4B99FF" class="up" @click="confirm">确认</van-button>
        </div>
      </van-form>
    </van-dialog>

    <!-- @肥肠安好/肺癌科研/精锐计划 项目弹出公告 start-->
    <van-dialog v-model="showDialog" :title="dialogTitle" :show-confirm-button="false">
      <van-form class="from" style="margin: 16px 10px" input-align="left">
        <div class="dialogContent" v-html="dialogContent"></div>
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit" color="#4B99FF" class="up" @click="comfirmDialog">确认</van-button>
        </div>
      </van-form>
    </van-dialog>
    <!-- @肥肠安好项目弹出公告 end-->

    <!-- 项目关闭公告 -->
    <close-notice></close-notice>

    <!-- 医生冻结弹窗 -->
    <froze-notice v-if="get_froze_status"></froze-notice>
    <!-- 实名制认证弹窗 -->
    <mobileVerify v-if="showVerify && !this.doctorInfo.has_empower" :ifShowBank="ifShowBank" :showBankcardPhoto="ifShowBankcardPhoto" :projectId="verifyProjectId" :afterModifyMobile="afterModifyMobile" :doctorId="userId" :bankcard="this.doctorInfo.bankcard" :resetDoctorInfo="true" :title="'实名制验证'" :tips="'实名制手机号需和银行卡预留手机号一致'" :tips_remain="'认证次数剩余' + valid_times + '次'" @hideVerify="hideVerify"></mobileVerify>
  </div>
</template>

<script>
import mobileVerify from '@/components/mobileVerify.vue'
import Tabbar from '@/components/tabbar.vue'
import closeNotice from '@/components/closeNotice.vue'
import frozeNotice from '@/components/frozeNotice.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { getSex } from '@/utils/utils.js'
import { doctor, addNotice, setPassword, projectDoctor_isMobileVerify, getSubProject, projectDoctor, getDoctorForm } from '@/api/api'
import { Dialog, Toast } from 'vant'
export default {
  name: '',
  components: {
    Tabbar,
    Toast,
    closeNotice,
    mobileVerify,
    frozeNotice
  },
  computed: {
    auth() {
      return this.is_realname == 1 ? '已实名认证' : '尚未实名认证'
    },
    authImg() {
      return this.is_realname == 1 ? '/static/identification_white.svg' : '/static/identification_white.svg'
    },
    authImgBottom() {
      return this.audit_status == '资质认证成功' ? '/static/identification_green.svg' : '/static/identification_gray.svg'
    },
    username() {
      if (this.id_type !== '身份证') {
        return this.name
      } else if (this.idsn) {
        return this.name[0] + getSex(this.idsn)
      } else {
        return '游客'
      }
    },
    ...mapGetters(['headimgurl', 'sex', 'get_froze_status']),
    ...mapState(['userId', 'basicInformation', 'doctorInfo', 'ifClosed', 'valid_times', 'ifFrozen', 'userInfo'])
  },
  data() {
    return {
      is_realname: 0,
      idsn: '',
      idsn_verify: '',
      name: '',
      id_type: '',
      mobile: '',
      record_count: '',
      record_pass_count: '',
      record_reject_count: '',
      list: [],
      projectDoctorRes: null,

      showWallet: false, //显示-提现中心
      showDialog: false,

      showChangePassword: false,
      newPassword: '',
      rePassword: '',
      dialogTitle: '公告',
      dialogContent: '',
      short_name: '', //@判断是哪个项目的公告
      showVerify: false, //是否显示实名制认证弹窗
      verifyProjectId: null, //项目id
      ifShowBank: false, //是否显示开户行及开户支行
      ifShowBankcardPhoto: false, //是否显示上传银行卡照片
      mobile_verify_position: null, //实名认证弹窗位置
      kv_url: '',
      audit_status: '', //资质认证状态
      has_password: null, //是否有密码
      ifCanChange: true
    }
  },
  async mounted() {
    this.init()
    console.log('doctorInfo', this.doctorInfo)
    this.$store.commit('clearRegisterInfo')
    console.log('clearRegisterInfo', this.registerInfo)
    // console.log("实名认证弹出位置(1,2)",this.basicInformation)
  },
  methods: {
    ...mapActions(['SET_INFORMATION', 'SET_IDS', 'GET_IF_CLOSED', 'SET_DOCTOR_ID', 'SET_PROJ_NAME', 'GET_IF_FROZEN']),
    ...mapMutations(['setValidTimes', 'setValidApply', 'setIsMobileVerify', 'setHasRecord']),
    clickTabbar(if_click) {
      console.log('if_click', if_click)
      if (if_click) {
        this.showMobileVerify().then(res => {
          console.log('if_Click_res', res)
          if (res) {
            this.ifCanChange = true
            console.log('this.ifCanChange ', this.ifCanChange)
          } else {
            console.log('11需要显示弹窗')
            this.ifCanChange = false
            this.showMobileVerify()
          }
        })
      }
    },
    // 关闭验证弹窗
    hideVerify(is_hide) {
      this.showVerify = is_hide
      console.log('关闭验证弹窗')
    },
    afterModifyMobile() {
      this.showVerify = false
    },
    async goWallet() {
      await getSubProject({
        params: {
          project_id: this.basicInformation.projects[0].id,
          doctor_id: this.basicInformation.id,
          enable: 1
        }
      }).then(result => {
        console.log('getSubProject', result)
        this.$store.commit('setSubid', result[0].id)
        console.log(result[0].id)
      })
      let res = await projectDoctor_isMobileVerify()
      //获取is_realname和is_mobile_verify
      if (this.doctorInfo.is_realname != 0 && res.is_mobile_verify != 0) {
        //通过全部验证
        console.log('通过全部验证')
        Dialog.confirm({
          title: '温馨提示',
          message: '即将带您前往益账户',
          confirmButtonColor: '#4B99FF'
        })
          .then(() => {
            // let  path = 'wallet'
            let path = 'login'
            if (location.hostname.indexOf('.papv2.sungotech.com') >= 0) {
              // window.location.href = 'http://eaccount.papwxtest.ilvzhou.com/?#/'+path
              window.location.href = 'http://eaccount.papwxtest.sungotech.com/?#/' + path
            } else {
              // window.location.href = 'http://eaccount.papwx.ilvzhou.com/?#/'+path
              window.location.href = 'http://eaccount.papwx.cpah.net.cn/?#/' + path
            }
          })
          .catch(() => {
            // on cancel
          })
      } else if (this.doctorInfo.is_realname != 1) {
        //未通过实名认证
        console.log('未通过实名认证')
        Dialog.alert({
          title: '提示',
          message: '您还未实名认证<br />认证成功后将跳转益账户',
          confirmButtonColor: '#4B99FF'
        }).then(() => {
          // 跳转实名认证
          // this.goOcr()
          console.log('跳转实名认证')
          this.showMobileVerify() //若未进行实名验证弹出实名制验证弹窗
        })
        return
      } else if (res.is_mobile_verify != 1) {
        //未通过四要素验证
        console.log('未通过四要素验证')
        Dialog.alert({
          title: '提示',
          message: '您还未认证实名制<br />认证成功后将跳转益账户',
          confirmButtonColor: '#4B99FF'
        }).then(() => {
          // 跳转四要素验证
          console.log('跳转四要素验证 ')
          this.showMobileVerify() //若未进行实名验证弹出实名制验证弹窗
        })
        return
      }
    },
    async init() {
      let res = await doctor({
        params: {
          doctor_id: this.userId
        }
      })
      this.SET_INFORMATION(res)
      this.$store.commit('setSubid', this.basicInformation.subprojects[0].id) //存入子项目id
      let projectDoctorRes = await projectDoctor()
      console.log('projectDoctorRes', projectDoctorRes)
      this.projectDoctorRes = projectDoctorRes
      this.setIsMobileVerify(projectDoctorRes.is_mobile_verify)
      this.setHasRecord(projectDoctorRes.has_record)
      this.setValidTimes(projectDoctorRes.bankcard_api_call_count)

      this.GET_IF_CLOSED() //查询项目是否关闭
      this.setValidApply(res.verify) //存储人工验证申请通道信息

      this.is_realname = res.is_realname
      this.idsn_verify = projectDoctorRes.idsn_verify
      this.name = res.name
      this.idsn = res.idsn
      this.id_type = res.id_type
      this.mobile = res.mobile
      this.record_count = res.record_count
      this.record_pass_count = res.record_pass_count
      this.record_reject_count = res.record_reject_count
      this.list = res.projects
      console.log('有志愿者身份医生', res.has_empower)
      this.showWallet = res.has_empower ? false : true
      this.kv_url = res.projects[0].kv //获取kv图
      this.SET_PROJ_NAME(res.projects[0].name)
      //只显示一次的弹框 (针对肥肠安好、精锐计划、肺癌科研项目)
      console.log('res.projects.is_read_notice', res.projects[0].is_read_notice)
      console.log('是否要显示公告(0:显示,1:不显示):', res.projects[0].is_read_notice)
      this.short_name = res.projects[0].short_name
      // this.short_name = "axwn"
      // if(this.short_name == "axwn" || this.short_name == 'axb' || this.short_name == 'bxzleq' || this.short_name == 'ryrs' || this.short_name == 'xhzl') { //如果是安心为尼项目、安欣保项目、靶向治疗二期、瑞意人生、消化肿瘤2.0项目则在实名制弹窗中显示开户行及开户支行
      //     this.ifShowBank = true
      // }
      if (this.basicInformation.subprojects[0].is_bankname_verify == 1) {
        this.ifShowBank = true
      }
      if (this.basicInformation.subprojects[0].is_collection_bankcard_photo == 1) {
        this.ifShowBankcardPhoto = true
      }
      this.verifyProjectId = res.projects[0].id
      this.has_password = res.has_password
      this.GET_IF_FROZEN() //查询医生是否被冻结
      // 目前弹窗出现的依次顺序：冻结弹窗 > 更新实名信息 > 公告 > 密码 > 四要素
      if (res.subprojects[0]?.notice) {
        if (res.projects[0].is_read_notice == 0 && !this.get_froze_status) {
          //get_froze_status为false表示医生未冻结则可以显示公告
          console.log('显示公告:', res.projects[0].is_read_notice)
          this.dialogContent = res.subprojects[0]?.notice
          this.showDialog = true
        }
      } else {
        console.log('无公告内容', res.projects[0].is_read_notice)
        if (this.has_password == 1) {
          this.showChangePassword = false
        } else {
          if (!this.get_froze_status) {
            //get_froze_status为false表示医生未冻结则可以显示修改密码
            this.showChangePassword = true
          } else {
            this.showChangePassword = false
          }
        }
      }
      this.SET_IDS({
        project_id: res.projects[0]?.id,
        sub_id: res.subprojects[0]?.id
      })
      console.log('实名制弹出位置', res.subprojects[0].mobile_verify_position)
      this.mobile_verify_position = res.subprojects[0].mobile_verify_position
      if (this.mobile_verify_position == '2' && this.showChangePassword == false && this.showDialog == false && !this.get_froze_status) {
        //弹出实名制认证弹窗
        this.showMobileVerify()
      }
      await this.auditInit()
    },
    auditInit() {
      // 获取资质认证状态
      getSubProject({
        params: {
          project_id: this.basicInformation.projects[0].id,
          doctor_id: this.basicInformation.id,
          enable: 1
        }
      }).then(async res => {
        console.log('子项目配置：', res[0].record_show_date)
        console.log('资质认证状态', res[0].audit_status)
        this.audit_status = res[0].audit_status
        this.$store.commit('setRecordShowDate', res[0].record_show_date)
        if (this.userInfo.doctor && this.userInfo.doctor.login_type == 'loginNoPassword') {
          const data = await getDoctorForm({
            params: {
              doctor_id: this.projectDoctorRes.doctor.id,
              subproject_id: res[0].id
            }
          })
          let toCert = false
          let toIdcard = false
          if (data.length > 0) {
            if (data[2].form[0].value.length === 0) {
              toCert = true
            }
            if (data[3].form.length > 0 && !data[3].is_edit_idcard) {
              toIdcard = true
            }
          }
          if (res[0].audit_status == '资质认证成功') {
            if (['1', '3'].includes(this.projectDoctorRes.resign_status)) {
              Dialog.alert({
                message: '请签署相关协议及知情同意书'
              }).then(() => {
                // 重签服务协议
                this.$router.replace({
                  path: '/re_agreement',
                  query: {
                    project_doctor_id: this.projectDoctorRes.id
                  }
                })
              })
            }
          } else if (res[0].audit_status !== '资质认证中') {
            Dialog.alert({
              message: res[0].audit_status == '资质认证失败' ? `驳回原因：${res[0].audit_remark}` : '请签署相关协议及知情同意书'
            }).then(() => {
              let path = '/add_agreement'
              let query = {
                project_doctor_id: res[0].project_doctor_id,
                follow_submit: 1
              }
              if (toCert) {
                path = '/add_idsn'
                query = {
                  project_doctor_id: res[0].project_doctor_id,
                  dire: 'front'
                }
              } else if (toIdcard) {
                path = 'add_idcard'
                query = {
                  project_doctor_id: res[0].project_doctor_id,
                  dire: 'front'
                }
              }
              if (res[0].audit_status == '资质认证失败') {
                query['audit_status'] = res[0].audit_status
                query['audit_remark'] = res[0].audit_remark
                path = '/add_hospital'
              }
              this.$router.replace({
                path: path,
                query: query
              })
            })
          }
        }
      })
    },
    // 弹出实名制认证弹窗
    async showMobileVerify() {
      let res = await projectDoctor() //获取can_mobile_verify和is_mobile_verify
      this.SET_DOCTOR_ID(res.id) //存入项目医生id
      this.setValidTimes(res.bankcard_api_call_count)
      let verifyFlag = !this.doctorInfo || res.is_mobile_verify != 1
      if (res.can_mobile_verify == true && !this.doctorInfo.has_empower && verifyFlag && this.mobile_verify_position == '2') {
        //四要素验证
        this.showVerify = true
        this.ifCanChange = false
        return false
      } else {
        this.ifCanChange = true
        return true
      }
    },
    goOcr() {
      if (this.is_realname == 1) return
      this.$router.replace({
        path: '/ocr'
      })
    },
    goProject() {
      if (this.is_realname == 1) {
        this.$router.replace({
          path: '/project'
        })
      } else {
        this.goOcr()
      }
    },
    goSet() {
      this.$router.replace({
        path: '/set'
      })
    },
    goSub(item) {
      this.showMobileVerify().then(res => {
        console.log('是否可以点进项目res', res)
        if (!res) {
          this.showMobileVerify() //若未进行实名验证弹出实名制验证弹窗
        } else if(this.idsn_verify && this.idsn_verify === '更新实名信息') {
          Dialog.confirm({
            message: '为了您更好的体验，根据项目要求，需要您更新身份证信息',
            confirmButtonColor: '#4B99FF',
            title: '提示'
          })
            .then(() => {
              // 重新上传身份证信息
              this.$router.replace({
                path: '/updateOcr',
                query: {
                  doctor_id: this.projectDoctorRes.doctor_id,
                  id: this.projectDoctorRes.id
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          if (this.audit_status == '资质认证成功' && this.short_name == 'bxzleq') {
            console.log('靶向治疗二期资质认证成功直接跳转到病例中心页面')
            this.$router.replace({
              //靶向治疗二期资质认证成功直接跳转到病例中心页面
              path: '/progress'
            })
          } else {
            if (this.is_realname == 1) {
              this.$router.replace({
                path: '/sub_project'
              })
              this.SET_IDS({
                project_id: item.id,
                sub_id: null
              })
            } else {
              this.goOcr()
            }
          }
        }
      })
    },
    async comfirmDialog() {
      this.showDialog = false
      await addNotice({
        project_id: this.list[0].id,
        doctor_id: this.userId
      })
        .then(res => {
          //已经确认弹框
          console.log('点击公告确认后的返回值:', res)
          if (this.has_password == 1) {
            this.showChangePassword = false
            this.showMobileVerify()
          } else {
            this.showChangePassword = true
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async confirm() {
      if (!this.newPassword || !this.rePassword) {
        Toast('更换失败，请完善信息')
        return false
      } else if (this.newPassword.length > 18 || this.newPassword.length < 8) {
        Toast('更换失败，请输入8-18位数的密码')
        return false
      } else if (this.newPassword != this.rePassword) {
        Toast('更换失败，请输入相同的密码')
        return false
      }
      try {
        await setPassword({
          mobile: this.mobile,
          password: this.newPassword,
          rePassword: this.rePassword,
          projectId: localStorage.getItem('project_id')
        }).then(res => {
          Toast(res.success)
          this.showChangePassword = false
          if (this.mobile_verify_position == '2') {
            //弹出实名制认证弹窗
            this.showMobileVerify()
          }
        })
      } catch (err) {
        console.log(err)
        if (err.response) Toast(err.response.data.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.me {
  padding: 9px 11px;
  box-sizing: border-box;

  ::v-deep .van-picker__confirm {
    color: #4b99ff;
  }

  .notice_title {
    font-size: 16px;
    font-weight: bold;
  }

  .up {
    font-size: 15px;
    font-family: PingFangSC-Medium;
    color: #ffffff;
  }

  .dialogContent {
    text-align: left;
  }

  .meInfo {
    width: 100%;
    position: relative;
    margin-bottom: 20px;

    .backImg {
      display: block;
      width: 100%;
      border-radius: 8px;
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 5px 0;
      box-sizing: border-box;

      .top {
        flex: 1;
        width: 100%;
        display: flex;

        .mine {
          // flex: 1;
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;

          .username_box {
            width: 120px;
            margin-bottom: 24px;

            .name {
              font-size: 16px;
              color: #ffffff;
              font-family: PingFangSC-Medium;
              text-align: left;
              margin-left: -8px;
            }

            .is_realname_bg {
              background: #7ad3ff;
            }

            .not_realname_bg {
              background: #2d5e9e;
              padding-left: 12px;
            }

            .certification {
              position: absolute;
              // width: 100%;
              height: 20px;
              // background: rgba(0, 0, 0, 0.6);
              // background: #2D5E9E;
              padding-right: 7px;
              // padding-left: 10px;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 125px;
              position: absolute;
              bottom: 16px;
              left: 25px;

              img {
                height: 13px;
                width: 13px;
                margin-right: 5px;
                margin-left: 2px;
              }

              span {
                font-size: 12px;
                color: #ffffff;
              }
            }
          }

          .img {
            width: 52px;
            height: 52px;
            background: #ffffff;
            border-radius: 50%;
            margin-right: 15px;
            margin-left: 11px;
            overflow: hidden;
            position: relative;
            z-index: 5;

            img {
              height: 100%;
              width: 100%;
            }
          }
        }

        .right {
          min-width: 70px;
          padding-top: 19px;
          box-sizing: border-box;
          position: relative;

          .set {
            position: absolute;
            // bottom: 5px;
            top: 18px;
            right: 18px;
            display: flex;
            align-items: center;

            img {
              height: 15px;
              width: 15px;
              margin-right: 3px;
            }

            span {
              font-size: 14px;
              color: #ffffff;
            }
          }
        }
      }

      .bottom {
        // height: 57px;
        height: 46px;
        width: 100%;
        border-radius: 12px;
        background: rgba(225, 225, 225, 0.1);
        border-radius: 0 0 11px 11px;
        display: flex;
        justify-content: space-around;

        .loading {
          text-align: center;
          font-size: 13px;
          color: #ffffff;
          padding-top: 8px;
          box-sizing: border-box;

          .number {
            font-weight: bold;
          }
        }
      }
    }
  }

  .wallet_box {
    box-shadow: 0px 1px 7px 0px rgba(35, 44, 57, 0.15);
    border-radius: 11px;
    height: 49px;
    padding: 10px 16px;
    display: flex;
    align-items: center;

    .icon_box {
      background: #edf5ff;
      display: flex;
      padding: 6px;
      border-radius: 6px;
    }

    .money {
      display: flex;
      align-items: center;

      img {
        height: 13px;
        width: 13px;
        margin-right: 10px;
        line-height: 33px;
      }

      span {
        color: #292929;
        font-size: 15px;
        font-family: PingFangSC-Regular;
      }
    }
  }

  .noIdsn {
    padding-top: 66px;
    box-sizing: border-box;

    img {
      width: 211px;
      margin-bottom: 39px;
    }
  }

  .noProject {
    padding-top: 21px;
    box-sizing: border-box;

    .myProject {
      text-align: left;
      font-size: 14px;
      color: #272727;
      margin-bottom: 24px;
    }

    img {
      width: 211px;
      margin-bottom: 18px;
    }

    .remark {
      font-size: 11px;
      color: #a8a8a8;
      margin-bottom: 14px;
    }
  }

  .projectList {
    padding-top: 21px;
    box-sizing: border-box;

    .myProject {
      text-align: left;
      font-size: 15px;
      color: #272727;
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      .line_icon {
        width: 6px;
        height: 17px;
        background: #4b99ff;
        border-radius: 4px;
        margin-right: 8px;
      }
    }

    .projectOne {
      width: 100%;
      position: relative;

      .project_item_box {
        color: #292929;
        font-size: 15px;
        font-family: PingFangSC-Medium;
        box-shadow: 0px 1px 7px 0px rgba(22, 23, 24, 0.15);
        border-radius: 8px;
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;
        border-radius: 4px;

        .proj_msg_box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 5px 0 8px 0;

          .projectName {
            font-size: 15px;
            color: #292929;
            text-align: left;
            font-family: PingFangSC-Medium;
          }

          .status_box {
            display: flex;
            align-items: center;

            .status_icon {
              img {
                width: 11px;
                height: 12px;
                display: flex;
                margin-right: 5px;
              }
            }

            .status_text {
              font-size: 12px;
            }

            .pass {
              color: #32c8c6;
            }

            .unpass {
              color: #c9c9c9;
            }
          }
        }

        .kv_box {
          // width: 94px;
          width: 43%;
          // height: 70px;
          background: #c9c9c9;
          border-radius: 4px;
          overflow: hidden;
          display: flex;

          img {
            width: 100%;
            // height: 100%;
            // max-height: 110px;
            max-height: 90px;
            object-fit: cover;
          }
        }
      }
    }
  }
}
</style>
